import React from 'react';
import './programOverview.scss';

export default function ProgramOverview({program}) {
  return (
    <section className='content program-overview'>
        <div className='preview'>
            <header>
                <img alt=""  className='avatar' src={program.preview.avatar}/>
                <h5 className='title'>{program.preview.title}</h5>
            </header>
            <div className='messages'>
            {
                program.preview.messages?.map((message,index) => {
                    return (<div className={'message ' + message.role} key={message.id || index}>
                        <span className='metadata'>
                            {message.image ? <img alt=""  src={message.image}/> : <></>}
                            {message.text}
                            <span className='metadata'>
                                <span className='time'>09:00 PM</span>
                                {message.role === "user" ? <span className='tick'/> : <></>}
                            </span>
                        </span>
                    </div>)
                })
            }
            </div>
        </div>
        <div className='info'>
            <header>
                <h2 className='title'>{program.info.title}</h2>
            </header>
            <main>
                <p className='subtitle'>{program.info.subtitle}</p>
                <div className='info'>{program.info.infoHTML}</div>
            </main>
        </div>
    </section>
  )
}
