import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Programs from './components/Programs/Programs';
// import Call from './components/Forms/Call/Call';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import Error404 from './components/Error404/Error404';
import { ProgramsProvider } from './context/programsContext';

function App() {
  return (
    <BrowserRouter>
        <div className="App">
          <Navigation/>

          <main>
            <Routes>
              <Route path='/' element={<Navigate to='/products'/>}/>
              <Route path='/products' element={<Programs/>}/>
              <Route path='/products/:product' element={<Programs/>}/>
              <Route path='*' element={<Error404/>}/>
            </Routes>
          </main>

          <Footer/>



          <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
          {/* <Call/> */}
        </div>
    </BrowserRouter>
  );
}

export default App;
